
/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/


#footer {
	position: relative;
	background-color: #EEE;
	border-top: 5px solid rgba(0,0,0,0.2);
}

@media (min-width: 992px) {
	.sticky-footer #slider:not(.slider-parallax-invisible),
	.sticky-footer #page-submenu,
	.sticky-footer #page-title,
	.sticky-footer #content {
		z-index: 2;
	}

	.sticky-footer #content {
		transform: translate3d(0,0,0);
	}

	.sticky-footer #footer {
		position: sticky;
		top: auto;
		bottom: 0;
		left: 0;
	}
}

#footer .footer-widgets-wrap {
	position: relative;
	padding: 80px 0;
}

#copyrights {
	padding: 40px 0;
	background-color: #DDD;
	font-size: 0.875rem;
	line-height: 1.8;
}

#copyrights i.footer-icon {
	position: relative;
	top: 1px;
	font-size: 0.875rem;
	width: 14px;
	text-align: center;
	margin-right: 3px;
}

.copyright-links a {
	display: inline-block;
	margin: 0 3px;
	color: #333;
	border-bottom: 1px dotted #444;
}

.copyright-links a:hover {
	color: #555;
	border-bottom: 1px solid #666;
}

.copyrights-menu { margin-bottom: 10px; }

.copyrights-menu a {
	font-size: 0.875rem;
	margin: 0 10px;
	border-bottom: 0 !important;
}

#copyrights a:first-child { margin-left: 0; }

#copyrights.text-end a:last-child { margin-right: 0; }

.footer-logo {
	display: block;
	margin-bottom: 30px;
}


/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/


.widget {
	position: relative;
	margin-top: 50px;
}

.widget p { line-height: 1.7; }

.sidebar-widgets-wrap .widget {
	padding-top: 50px;
	border-top: 1px solid #EEE;
}

.widget:first-child { margin-top: 0; }

.sidebar-widgets-wrap .widget:first-child {
	padding-top: 0;
	border-top: 0;
}

@media (min-width: 768px) and (max-width: 991.98px) {
	.sidebar-widgets-wrap {
		position: relative;
		display: -ms-flex;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin: 0 -20px -40px -20px;
	}

	.sidebar-widgets-wrap .widget {
		-ms-flex: 0 0 50%;
		flex: 0 0 50%;
		max-width: 50%;
		margin: 0 0 40px 0;
		padding-top: 0;
		padding-left: 20px;
		padding-right: 20px;
		border-top: 0;
	}
}

.widget > h4 {
	margin-bottom: 25px;
	font-size: 15px;
	font-weight: 600;
	letter-spacing: 2px;
	text-transform: uppercase;
}

.sidebar-widgets-wrap .widget > h4 { letter-spacing: 1px; }


/* Tag Cloud
-----------------------------------------------------------------*/


.tagcloud { margin-bottom: -2px; }

.tagcloud a {
	display: block;
	float: left;
	margin-right: 4px;
	margin-bottom: 4px;
	padding: 3px 6px;
	border: 1px solid #E5E5E5;
	font-size: 13px !important;
	color: #666;
	border-radius: 2px;
}

body:not(.device-touch) .tagcloud a {
	-webkit-transition: all .3s ease-in-out;
	-o-transition: all .3s ease-in-out;
	transition: all .3s ease-in-out;
}

.tagcloud a:hover {
	border-color: #1ABC9C !important;
	color: #1ABC9C !important;
}


/* Widget - Links
-----------------------------------------------------------------*/

.widget_nav_menu ul,
.widget_nav_menu li,
.widget_links ul,
.widget_links li,
.widget_meta ul,
.widget_meta li,
.widget_archive ul,
.widget_archive li,
.widget_recent_comments ul,
.widget_recent_comments li,
.widget_recent_entries ul,
.widget_recent_entries li,
.widget_categories ul,
.widget_categories li,
.widget_pages ul,
.widget_pages li,
.widget_rss ul,
.widget_rss li {
	list-style: none;
	margin: 0;
}

.widget_nav_menu li,
.widget_links li,
.widget_meta li,
.widget_archive li,
.widget_recent_comments li,
.widget_recent_entries li,
.widget_categories li,
.widget_pages li,
.widget_rss li {
	display: flex;
	padding: 4px 0;
	font-size: 15px;
}

.widget_nav_menu:not(.widget-li-noicon) li::before,
.widget_links:not(.widget-li-noicon) li::before,
.widget_meta:not(.widget-li-noicon) li::before,
.widget_archive:not(.widget-li-noicon) li::before,
.widget_recent_entries:not(.widget-li-noicon) li::before,
.widget_categories:not(.widget-li-noicon) li::before,
.widget_pages:not(.widget-li-noicon) li::before,
.widget_rss:not(.widget-li-noicon) li::before,
.widget_recent_comments:not(.widget-li-noicon) li::before {
	content: "\e7a5";
	font-family: 'font-icons';
	display: inline-block;
	position: relative;
	top: 1px;
	left: 0;
}

.widget_recent_comments li::before {
	content: "\e9b8";
}

.widget_nav_menu li a,
.widget_links li a,
.widget_meta li a,
.widget_archive li a,
.widget_recent_entries li a,
.widget_categories li a,
.widget_pages li a,
.widget_rss li a,
.widget_recent_comments li a {
	display: inline-block;
	padding: 0 3px 0 12px;
	border: none !important;
	color: #444;
}

.widget_nav_menu.widget-li-noicon li a,
.widget_links.widget-li-noicon li a,
.widget_meta.widget-li-noicon li a,
.widget_archive.widget-li-noicon li a,
.widget_recent_entries.widget-li-noicon li a,
.widget_categories.widget-li-noicon li a,
.widget_pages.widget-li-noicon li a,
.widget_rss.widget-li-noicon li a,
.widget_recent_comments.widget-li-noicon li a {
	padding-left: 0;
}

.widget_nav_menu li a:hover,
.widget_links li a:hover,
.widget_meta li a:hover,
.widget_archive li a:hover,
.widget_recent_entries li a:hover,
.widget_categories li a:hover,
.widget_pages li a:hover,
.widget_rss li a:hover,
.widget_recent_comments li a:hover { color: #000; }

.widget_nav_menu > ul > li:first-child,
.widget_links > ul > li:first-child,
.widget_meta > ul > li:first-child,
.widget_archive > ul > li:first-child,
.widget_recent_comments > ul > li:first-child,
.widget_recent_entries > ul > li:first-child,
.widget_categories > ul > li:first-child,
.widget_pages > ul > li:first-child,
.widget_rss > ul > li:first-child { border-top: 0 !important; }

.widget_nav_menu > ul,
.widget_links > ul,
.widget_meta > ul,
.widget_archive > ul,
.widget_recent_comments > ul,
.widget_recent_entries > ul,
.widget_categories > ul,
.widget_pages > ul,
.widget_rss > ul { margin-top: -4px !important; }

.widget_nav_menu ul ul,
.widget_links ul ul,
.widget_meta ul ul,
.widget_archive ul ul,
.widget_recent_comments ul ul,
.widget_recent_entries ul ul,
.widget_categories ul ul,
.widget_pages ul ul,
.widget_rss ul ul { margin-left: 15px; }


/* Widget - Testimonial & Twitter
-----------------------------------------------------------------*/

.widget .testimonial.no-image .testi-image { display: none; }

.widget .testimonial.twitter-scroll .testi-image { margin-right: 10px; }

.widget .testimonial:not(.twitter-scroll) .testi-image,
.widget .testimonial:not(.twitter-scroll) .testi-image a,
.widget .testimonial:not(.twitter-scroll) .testi-image img {
	width: 42px;
	height: 42px;
}

.widget .testimonial.twitter-scroll .testi-image,
.widget .testimonial.twitter-scroll .testi-image a,
.widget .testimonial.twitter-scroll .testi-image img,
.widget .testimonial.twitter-scroll .testi-image i {
	width: 28px;
	height: 28px;
}

.widget .testimonial.twitter-scroll .testi-image i {
	background-color: #EEE;
	line-height: 28px;
	font-size: 0.875rem;
	color: #888;
}

.widget .testimonial p { font-size: 0.875rem; }


/* Widget - Quick Contact Form
-----------------------------------------------------------------*/

.quick-contact-widget.form-widget .form-control,
.quick-contact-widget.form-widget .input-group,
.quick-contact-widget.form-widget .sm-form-control { margin-bottom: 10px; }

.quick-contact-widget.form-widget .input-group .form-control { margin-bottom: 0; }

.quick-contact-widget.form-widget form,
#template-contactform { position: relative; }


/* Newsletter Widget
-----------------------------------------------------------------*/

.subscribe-widget h5 {
	font-weight: 300;
	font-size: 0.875rem;
	line-height: 1.5;
}


/* Twitter Feed Widget
-----------------------------------------------------------------*/

.widget-twitter-feed li { margin: 15px 0 0 0; }

.widget-twitter-feed li:first-child { margin-top: 0; }

.widget-twitter-feed small {
	display: block;
	margin-top: 3px;
}

.widget-twitter-feed small a:not(:hover) { color: #999; }

.widget-twitter-feed .twitter-feed:not(.twitter-feed-avatar) a.twitter-avatar { display: none; }

.widget-twitter-feed .twitter-feed.twitter-feed-avatar i.icon-twitter { display: none; }

.widget-twitter-feed .twitter-feed-avatar { margin-left: 44px; }

.widget-twitter-feed .twitter-feed-avatar > li a.twitter-avatar {
	display: block;
	position: absolute;
	left: -44px;
	text-align: center;
	top: 2px;
	width: 32px;
	height: 32px;
}

.twitter-feed-avatar a.twitter-avatar img { border-radius: 50%; }


/* Widget Filter Links
-----------------------------------------------------------------*/

.widget-filter-links ul {
	list-style: none;
	margin-bottom: 0;
}

.widget-filter-links .widget-filter-reset {
	position: absolute;
	top: 0;
	left: auto;
	right: 0;
	font-size: 12px;
	line-height: 22px;
}

.widget:not(:first-child) .widget-filter-reset { top: 50px; }

.widget-filter-links .widget-filter-reset a { color: red !important; }

.widget-filter-links .widget-filter-reset.active-filter { display: none; }

.widget-filter-links li {
	position: relative;
	font-size: 15px;
	line-height: 24px;
}

.widget-filter-links li:not(:first-child) { margin-top: 10px; }

.widget-filter-links li a {
	display: block;
	color: #444;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
}

.widget-filter-links li span {
	position: absolute;
	top: 1px;
	left: auto;
	right: 0;
	font-size: 11px;
	width: 22px;
	height: 22px;
	line-height: 22px;
	text-align: center;
	color: #777;
	background-color: #EEE;
	border-radius: 50%;
}

.widget-filter-links li:hover a { color: #777; }

.widget-filter-links li.active-filter a { font-weight: 700; }

.widget-filter-links li.active-filter span {
	color: #FFF !important;
	background-color: #1ABC9C !important;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.1);
}


/* Navigation Tree
-----------------------------------------------------------------*/

.nav-tree {
	position: relative;
	margin-bottom: 40px;
}

.nav-tree ul {
	margin-bottom: 0;
	list-style: none;
}

.nav-tree > ul { margin: -8px 0; }

.nav-tree li { position: relative; }

.nav-tree li a {
	display: block;
	padding: 8px 0;
	color: #333;
	text-transform: uppercase;
	font-size: 13px;
	font-weight: 700;
	letter-spacing: 2px;
	font-family: 'Poppins', sans-serif;
}

.nav-tree li i {
	font-size: 0.875rem;
	width: 16px;
	text-align: center;
}

.nav-tree li i:not(.icon-angle-down) {
	margin-right: 8px;
	position: relative;
	top: 1px;
}

.nav-tree li a i.icon-angle-down {
	width: auto;
	font-size: 12px;
	margin-left: 2px;
}

.nav-tree li:hover > a,
.nav-tree li.current > a,
.nav-tree li.active > a { color: #1ABC9C !important; }

.nav-tree ul ul { display: none; }

.nav-tree li.current > ul { display: block; }

.nav-tree ul ul a {
	font-size: 12px;
	padding: 6px 0;
	letter-spacing: 1px;
	font-family: 'Lato', sans-serif;
}

.nav-tree ul ul a i.icon-angle-down { font-size: 12px; }

.nav-tree ul ul a { padding-left: 20px; }
.nav-tree ul ul ul a { padding-left: 40px; }
.nav-tree ul ul ul ul a { padding-left: 60px; }
.nav-tree ul ul ul ul ul a { padding-left: 80px; }


/* ----------------------------------------------------------------
	Wedding
-----------------------------------------------------------------*/


.wedding-head {
	position: relative;
	line-height: 1;
	font-size: 80px;
	text-shadow: 1px 1px 1px rgba(0,0,0,0.15);
	font-family: 'Poppins', sans-serif;
}

.wedding-head .first-name,
.wedding-head .last-name,
.wedding-head .and {
	display: inline-block;
	margin-right: 15px;
	font-weight: bold;
	text-align: right;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.wedding-head .last-name {
	margin: 0 0 0 15px;
	text-align: left;
}

.wedding-head .first-name span,
.wedding-head .last-name span {
	display: block;
	margin-top: 10px;
	font-size: 56px;
	font-weight: 400;
	font-style: italic;
	font-family: 'PT Serif', serif;
	text-transform: none;
}

.wedding-head .and {
	margin: 0;
	font-size: 200px;
	font-family: 'Times New Roman', serif;
}


/* ----------------------------------------------------------------
	Styled Paginations
-----------------------------------------------------------------*/


.pagination.pagination-circle .page-item .page-link {
	width: 35px;
	height: 35px;
	line-height: 33px;
	text-align: center;
	padding: 0;
	border-radius: 50%;
}

.pagination.pagination-circle.pagination-lg .page-item .page-link {
	width: 56px;
	height: 56px;
	line-height: 54px;
}

.pagination.pagination-circle.pagination-sm .page-item .page-link {
	width: 26px;
	height: 26px;
	line-height: 24px;
}

/* Pagination Rounded */
.pagination.pagination-rounded .page-item .page-link { border-radius: 4px; }

/* Pagination Margins */
.pagination.pagination-circle .page-item:not(:first-child) .page-link,
.pagination.pagination-rounded .page-item:not(:first-child) .page-link { margin-left: 5px; }

.pagination.pagination-circle.pagination-lg .page-item:not(:first-child) .page-link,
.pagination.pagination-rounded.pagination-lg .page-item:not(:first-child) .page-link { margin-left: 8px; }

.pagination.pagination-circle.pagination-sm .page-item:not(:first-child) .page-link,
.pagination.pagination-rounded.pagination-sm .page-item:not(:first-child) .page-link { margin-left: 3px; }

/* Pagination 3d */
.pagination.pagination-3d .page-item .page-link { border-bottom: 3px solid rgba(0,0,0,0.15) !important; }

/* Pagination Transparent */
.pagination.pagination-transparent .page-item .page-link { border: 0; }

/* Pagination Transparent */
.pagination.pagination-inside-transparent .page-item .page-link:not(:hover) { color: #222; }

/* Pagination Inside Transparent */
.pagination.pagination-inside-transparent .page-item:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)) .page-link { border-left: 0; }

.pagination.pagination-inside-transparent .page-item.active .page-link,
.pagination.pagination-inside-transparent .page-link:hover,
.pagination.pagination-inside-transparent .page-link:focus { border-color: transparent !important; }

/* Pagination Button */
.pagination.pagination-inside-transparent.pagination-button .page-item:not(:first-child):not(:nth-last-child(1)) .page-link { border: 0; }

/* Pagination Pill */
.pagination.pagination-pill .page-item:first-child .page-link {
	border-top-left-radius: 10rem;
	border-bottom-left-radius: 10rem;
}

.pagination.pagination-pill .page-item:last-child .page-link {
	border-top-right-radius: 10rem;
	border-bottom-right-radius: 10rem;
}

/* Pagination primary */
.pagination.pagination-primary .page-item .page-link {
	background-color: #007bff;
	background-color: var(--primary);
	border-color: #007bff;
	border-color: var(--primary);
	color: #FFF;
}

/* Pagination secondary */
.pagination.pagination-secondary .page-item .page-link {
	background-color: #6c757d;
	background-color: var(--secondary);
	border-color: #6c757d;
	border-color: var(--secondary);
	color: #FFF;
}

/* Pagination success */
.pagination.pagination-success .page-item .page-link {
	background-color: #28a745;
	background-color: var(--success);
	border-color: #28a745;
	border-color: var(--success);
	color: #FFF;
}

/* Pagination danger */
.pagination.pagination-danger .page-item .page-link {
	background-color: #dc3545;
	background-color: var(--danger);
	border-color: #dc3545;
	border-color: var(--danger);
	color: #FFF;
}

/* Pagination info */
.pagination.pagination-info .page-item .page-link {
	background-color: #17a2b8;
	background-color: var(--info);
	border-color: #17a2b8;
	border-color: var(--info);
	color: #FFF;
}

/* Pagination dark */
.pagination.pagination-dark .page-item .page-link {
	background-color: #343a40;
	background-color: var(--dark);
	border-color: #343a40;
	border-color: var(--dark);
	color: #FFF;
}

/* Pagination light */
.pagination.pagination-light .page-item .page-link {
	background-color: #f8f9fa;
	background-color: var(--light);
	border-color: #f8f9fa;
	border-color: var(--light);
	color: #222;
}

/* Pagination Bootstrap Background */
.pagination.pagination-primary .page-item.active .page-link,
.pagination.pagination-primary .page-link:hover,
.pagination.pagination-primary .page-link:focus,
.pagination.pagination-secondary .page-item.active .page-link,
.pagination.pagination-secondary .page-link:hover,
.pagination.pagination-secondary .page-link:focus,
.pagination.pagination-success .page-item.active .page-link,
.pagination.pagination-success .page-link:hover,
.pagination.pagination-success .page-link:focus,
.pagination.pagination-danger .page-item.active .page-link,
.pagination.pagination-danger .page-link:hover,
.pagination.pagination-danger .page-link:focus,
.pagination.pagination-info .page-item.active .page-link,
.pagination.pagination-info .page-link:hover,
.pagination.pagination-info .page-link:focus,
.pagination.pagination-light .page-item.active .page-link,
.pagination.pagination-light .page-link:hover,
.pagination.pagination-light .page-link:focus {
	background-color: #222 !important;
	border-color: #222 !important;
	color: #FFF;
}


/* --------------------------------------------------------------
	SWITCH
--------------------------------------------------------------  */

.switch-toggle {
	position: absolute;
	margin-left: -9999px;
	visibility: hidden;
}

.switch-toggle + label {
	display: block;
	position: relative;
	cursor: pointer;
	outline: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* --------------------------------------------------------------
	SWITCH 1 - ROUND
----------------------------------------------------------------- */
input.switch-toggle-round + label {
	padding: 2px;
	width: 60px;
	height: 30px;
	background-color: #DDD;
	-webkit-border-radius: 15px;
	-o-border-radius: 15px;
	border-radius: 15px;
}

input.switch-toggle-round + label::before,
input.switch-toggle-round + label::after {
	display: block;
	position: absolute;
	top: 1px;
	left: 1px;
	bottom: 1px;
	content: "";
}

input.switch-toggle-round + label::before {
	right: 1px;
	background-color: #F1F1F1;
	-webkit-border-radius: 15px;
	-o-border-radius: 15px;
	border-radius: 15px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.switch-toggle-round + label::after {
	width: 28px;
	background-color: #FFF;
	-webkit-border-radius: 100%;
	-o-border-radius: 100%;
	border-radius: 100%;
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	-moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
	-webkit-transition: margin 0.4s;
	-moz-transition: margin 0.4s;
	-o-transition: margin 0.4s;
	transition: margin 0.4s;
}

input.switch-toggle-round:checked + label::before { background-color: #1ABC9C; }
input.switch-toggle-round:checked + label::after { margin-left: 30px; }

/* --------------------------------------------------------------
	SWITCH 1 - ROUND- MINI
----------------------------------------------------------------- */
input.switch-rounded-mini.switch-toggle-round + label {
	padding: 1px;
	width: 32px;
	height: 16px;
	-webkit-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
}

input.switch-rounded-mini.switch-toggle-round + label::before {
	-webkit-border-radius: 8px;
	-o-border-radius: 8px;
	border-radius: 8px;
}

input.switch-rounded-mini.switch-toggle-round + label::after { width: 15px; }
input.switch-rounded-mini.switch-toggle-round:checked + label::after { margin-left: 15px; }

/* --------------------------------------------------------------
	SWITCH 1 - ROUND- LARGE
----------------------------------------------------------------- */
input.switch-rounded-large.switch-toggle-round + label {
	width: 90px;
	height: 45px;
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-rounded-large.switch-toggle-round + label::before {
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-rounded-large.switch-toggle-round + label::after { width: 43px; }
input.switch-rounded-large.switch-toggle-round:checked + label::after { margin-left: 45px; }


/* --------------------------------------------------------------
	SWITCH 1 - ROUND- XLARGE
----------------------------------------------------------------- */
input.switch-rounded-xlarge.switch-toggle-round + label {
	width: 120px;
	height: 60px;
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}

input.switch-rounded-xlarge.switch-toggle-round + label::before {
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}

input.switch-rounded-xlarge.switch-toggle-round + label::after { width: 58px; }
input.switch-rounded-xlarge.switch-toggle-round:checked + label::after { margin-left: 60px; }


/* -----------------------------------------------------------
	SWITCH 2 - ROUND FLAT
-------------------------------------------------------------- */
input.switch-toggle-flat + label {
	padding: 2px;
	width: 60px;
	height: 30px;
	background-color: #DDD;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.switch-toggle-flat + label::before,
input.switch-toggle-flat + label::after {
	display: block;
	position: absolute;
	content: "";
}

input.switch-toggle-flat + label::before {
	top: 2px;
	left: 2px;
	bottom: 2px;
	right: 2px;
	background-color: #FFF;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	-ms-border-radius: 30px;
	-o-border-radius: 30px;
	border-radius: 30px;
	-webkit-transition: background 0.4s;
	-moz-transition: background 0.4s;
	-o-transition: background 0.4s;
	transition: background 0.4s;
}

input.switch-toggle-flat + label::after {
	top: 4px;
	left: 4px;
	bottom: 4px;
	width: 22px;
	background-color: #DDD;
	-webkit-border-radius: 22px;
	-moz-border-radius: 22px;
	-ms-border-radius: 22px;
	-o-border-radius: 22px;
	border-radius: 22px;
	-webkit-transition: margin 0.4s, background 0.4s;
	-moz-transition: margin 0.4s, background 0.4s;
	-o-transition: margin 0.4s, background 0.4s;
	transition: margin 0.4s, background 0.4s;
}

input.switch-toggle-flat:checked + label { background-color: #1ABC9C; }

input.switch-toggle-flat:checked + label::after {
	margin-left: 30px;
	background-color: #1ABC9C;
}


/* -----------------------------------------------------------
	SWITCH 2 - FLAT - MINI
-------------------------------------------------------------- */
input.switch-flat-mini.switch-toggle-flat + label {
	padding: 1px;
	width: 32px;
	height: 16px;
	-webkit-border-radius: 16px;
	-o-border-radius: 16px;
	border-radius: 16px;
}

input.switch-flat-mini.switch-toggle-flat + label::before {
	top: 1px;
	left: 1px;
	bottom: 1px;
	right: 1px;
	-webkit-border-radius: 16px;
	-o-border-radius: 16px;
	border-radius: 16px;
}

input.switch-flat-mini.switch-toggle-flat + label::after {
	top: 2px;
	left: 2px;
	bottom: 2px;
	width: 12px;
	-webkit-border-radius: 12px;
	-o-border-radius: 12px;
	border-radius: 12px;
}

input.switch-flat-mini.switch-toggle-flat:checked + label::after { margin-left: 16px; }


/* -----------------------------------------------------------
	SWITCH 2 - FLAT - LARGE
-------------------------------------------------------------- */
input.switch-flat-large.switch-toggle-flat + label {
	width: 90px;
	height: 45px;
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-flat-large.switch-toggle-flat + label::before {
	-webkit-border-radius: 45px;
	-o-border-radius: 45px;
	border-radius: 45px;
}

input.switch-flat-large.switch-toggle-flat + label::after {
	width: 37px;
	-webkit-border-radius: 37px;
	-o-border-radius: 37px;
	border-radius: 37px;
}

input.switch-flat-large.switch-toggle-flat:checked + label::after { margin-left: 45px; }



/* -----------------------------------------------------------
	SWITCH 2 - FLAT - XLARGE
-------------------------------------------------------------- */
input.switch-flat-xlarge.switch-toggle-flat + label {
	padding: 2px;
	width: 120px;
	height: 60px;
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}

input.switch-flat-xlarge.switch-toggle-flat + label::before {
	-webkit-border-radius: 60px;
	-o-border-radius: 60px;
	border-radius: 60px;
}
input.switch-flat-xlarge.switch-toggle-flat + label::after {
	width: 52px;
	-webkit-border-radius: 52px;
	-o-border-radius: 52px;
	border-radius: 52px;
}

input.switch-flat-xlarge.switch-toggle-flat:checked + label::after { margin-left: 60px; }


.show-error-msg .switch + label.error {
	display: block !important;
}

.switch input[type="checkbox"].error + label {
	border: 1px solid red;
}


/* ----------------------------------------------------------------
	Bootstrap Specific
-----------------------------------------------------------------*/

.table .success { color: #3c763d; }

.table .info { color: #31708f; }

.table .warning { color: #8a6d3b; }

.table .danger { color: #a94442; }

.btn-link,
.page-link,
.page-link:hover,
.page-link:focus { color: #1ABC9C; }

.btn-link:hover,
.btn-link:focus { color: #222; }

.boot-tabs { margin-bottom: 20px !important; }

.carousel-control .icon-chevron-left,
.carousel-control .icon-chevron-right {
	position: absolute;
	top: 47.5%;
	z-index: 5;
	display: inline-block;
}

.carousel-control .icon-chevron-left { left: 50%; }

.carousel-control .icon-chevron-right { right: 50%; }

.responsive-utilities th small {
	display: block;
	font-weight: 400;
	color: #999;
}

.show-grid [class^=col-] {
	padding-top: 10px;
	padding-bottom: 10px;
	background-color: #EEE;
	border: 1px solid #DDD;
}

.img-circle { border-radius: 50% !important; }

.page-item.active .page-link,
.page-link:hover,
.page-link:focus {
	color: #FFF !important;
	background-color: #1ABC9C !important;
	border-color: #1ABC9C !important;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus { background-color: #1ABC9C; }

label.radio,
label.checkbox { padding-left: 20px; }

.input-group-lg > .input-group-addon { line-height: 1; }


/* ----------------------------------------------------------------
	Stretched Layout
-----------------------------------------------------------------*/


.stretched #wrapper {
	width: 100%;
	margin: 0;
	box-shadow: none;
}


/* ----------------------------------------------------------------
	Shape Dividers
-----------------------------------------------------------------*/


.shape-divider {
	position: absolute;
	top: -1px;
	left: 0;
	width: 100%;
	overflow: hidden;
	z-index: 1;
}

.shape-divider[data-position="bottom"] {
	top: auto;
	bottom: -1px;
}

.shape-divider[data-front="true"] {
	z-index: 4;
}

.shape-divider svg {
	position: relative;
	width: 100%;
	width: calc( 100% + 1.3px );
	height: 50px;
	left: 50%;
	transform: translateX(-50%);
	opacity: 0;
}

.shape-divider[data-position="bottom"],
.shape-divider[data-flip-vertical="true"] {
	transform: rotate(180deg);
}

.shape-divider[data-position="bottom"][data-flip-vertical="true"] {
	transform: rotate(0deg);
}

.shape-divider[data-flip="true"] svg {
	transform: translateX(-50%) rotateY(180deg);
}

.shape-divider-fill {
	fill: #FFF;
	transform-origin: center;
	transform: rotateY(0deg);
}


/* ----------------------------------------------------------------
	Page Transitions
-----------------------------------------------------------------*/

.page-transition #wrapper,
.animsition-overlay {
	position: relative;
	opacity: 0;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.page-transition-wrap,
.css3-spinner {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1000;
	text-align: center;
	background-color: #FFF;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-perspective: 1000;
}

.css3-spinner {
	position: absolute;
	z-index: auto;
	background-color: transparent;
}

.css3-spinner > div {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -9px;
	margin-left: 13px;
	width: 18px;
	height: 18px;
	background-color: #DDD;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
	animation: bouncedelay 1.4s infinite ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner .css3-spinner-bounce1 {
	margin-left: -31px;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.css3-spinner .css3-spinner-bounce2 {
	margin-left: -9px;
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%, 80%, 100% { -webkit-transform: scale(0.0) }
	40% { -webkit-transform: scale(1.0) }
}

@keyframes bouncedelay {
	0%, 80%, 100% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
	} 40% {
	transform: scale(1.0);
	-webkit-transform: scale(1.0);
	}
}


.css3-spinner > .css3-spinner-flipper {
	width: 32px;
	height: 32px;
	margin-top: -16px;
	margin-left: -16px;
	border-radius: 0;
	-webkit-animation: rotateplane 1.2s infinite ease-in-out;
	animation: rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes rotateplane {
	0% { -webkit-transform: perspective(120px) }
	50% { -webkit-transform: perspective(120px) rotateY(180deg) }
	100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
	} 50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
	} 100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

.css3-spinner > .css3-spinner-double-bounce1,
.css3-spinner > .css3-spinner-double-bounce2 {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	border-radius: 50%;
	opacity: 0.6;
	-webkit-animation: cssspinnerbounce 2.0s infinite ease-in-out;
	animation: cssspinnerbounce 2.0s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-double-bounce2 {
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

@-webkit-keyframes cssspinnerbounce {
	0%, 100% { -webkit-transform: scale(0.0) }
	50% { -webkit-transform: scale(1.0) }
}

@keyframes cssspinnerbounce {
	0%, 100% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
	} 50% {
	transform: scale(1.0);
	-webkit-transform: scale(1.0);
	}
}

.css3-spinner > .css3-spinner-rect1,
.css3-spinner > .css3-spinner-rect2,
.css3-spinner > .css3-spinner-rect3,
.css3-spinner > .css3-spinner-rect4,
.css3-spinner > .css3-spinner-rect5 {
	height: 30px;
	width: 6px;
	margin-top: -15px;
	margin-left: -21px;
	border-radius: 0;
	-webkit-animation: stretchdelay 1.2s infinite ease-in-out;
	animation: stretchdelay 1.2s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-rect2 {
	margin-left: -12px;
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}

.css3-spinner > .css3-spinner-rect3 {
	margin-left: -3px;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

.css3-spinner > .css3-spinner-rect4 {
	margin-left: 6px;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

.css3-spinner > .css3-spinner-rect5 {
	margin-left: 15px;
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
	0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
	20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
	0%, 40%, 100% {
	transform: scaleY(0.4);
	-webkit-transform: scaleY(0.4);
	}  20% {
	transform: scaleY(1.0);
	-webkit-transform: scaleY(1.0);
	}
}


.css3-spinner > .css3-spinner-cube1,
.css3-spinner > .css3-spinner-cube2 {
	width: 16px;
	height: 16px;
	border-radius: 0;
	margin-top: -20px;
	margin-left: -20px;
	-webkit-animation: cubemove 1.8s infinite ease-in-out;
	animation: cubemove 1.8s infinite ease-in-out;
}

.css3-spinner > .css3-spinner-cube2 {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

@-webkit-keyframes cubemove {
	25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
	50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
	75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
	100% { -webkit-transform: rotate(-360deg) }
}

@keyframes cubemove {
	25% {
	transform: translateX(42px) rotate(-90deg) scale(0.5);
	-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	} 50% {
	transform: translateX(42px) translateY(42px) rotate(-179deg);
	-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	} 50.1% {
	transform: translateX(42px) translateY(42px) rotate(-180deg);
	-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	} 75% {
	transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	} 100% {
	transform: rotate(-360deg);
	-webkit-transform: rotate(-360deg);
	}
}


.css3-spinner > .css3-spinner-scaler {
	width: 40px;
	height: 40px;
	margin-top: -20px;
	margin-left: -20px;
	-webkit-animation: scaleout 1.0s infinite ease-in-out;
	animation: scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes scaleout {
	0% { -webkit-transform: scale(0.0) }
	100% {
		-webkit-transform: scale(1.0);
		opacity: 0;
	}
}

@keyframes scaleout {
	0% {
	transform: scale(0.0);
	-webkit-transform: scale(0.0);
	} 100% {
	transform: scale(1.0);
		-webkit-transform: scale(1.0);
		opacity: 0;
	}
}


.css3-spinner > .css3-spinner-grid-pulse {
	display: -ms-flex;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 48px;
	height: 48px;
	margin-top: -24px;
	margin-left: -24px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-grid-pulse > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	max-width: 12px;
	border-radius: 100%;
	margin: 2px;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	display: inline-block;
	-webkit-animation-name: ball-grid-pulse;
	animation-name: ball-grid-pulse;
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-delay: 0;
	animation-delay: 0;
}

.css3-spinner-grid-pulse > div:nth-child(1) {
	-webkit-animation-delay: 0.73s;
	animation-delay: 0.73s;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
}

.css3-spinner-grid-pulse > div:nth-child(2) {
	-webkit-animation-delay: 0.32s;
	animation-delay: 0.32s;
	-webkit-animation-duration: 1.3s;
	animation-duration: 1.3s;
}

.css3-spinner-grid-pulse > div:nth-child(3) {
	-webkit-animation-delay: 0.71s;
	animation-delay: 0.71s;
	-webkit-animation-duration: 0.88s;
	animation-duration: 0.88s;
}

.css3-spinner-grid-pulse > div:nth-child(4) {
	-webkit-animation-delay: 0.62s;
	animation-delay: 0.62s;
	-webkit-animation-duration: 1.06s;
	animation-duration: 1.06s;
}

.css3-spinner-grid-pulse > div:nth-child(5) {
	-webkit-animation-delay: 0.31s;
	animation-delay: 0.31s;
	-webkit-animation-duration: 0.62s;
	animation-duration: 0.62s;
}

.css3-spinner-grid-pulse > div:nth-child(6) {
	-webkit-animation-delay: -0.14s;
	animation-delay: -0.14s;
	-webkit-animation-duration: 1.48s;
	animation-duration: 1.48s;
}

.css3-spinner-grid-pulse > div:nth-child(7) {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
	-webkit-animation-duration: 1.47s;
	animation-duration: 1.47s;
}

.css3-spinner-grid-pulse > div:nth-child(8) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	-webkit-animation-duration: 1.49s;
	animation-duration: 1.49s;
}

.css3-spinner-grid-pulse > div:nth-child(9) {
	-webkit-animation-delay: 0.73s;
	animation-delay: 0.73s;
	-webkit-animation-duration: 0.7s;
	animation-duration: 0.7s;
}

@-webkit-keyframes ball-grid-pulse {
	0% {
	-webkit-transform: scale(1);
	transform: scale(1); }

	50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	opacity: 0.7; }

	100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1; }
}

@keyframes ball-grid-pulse {
	0% {
	-webkit-transform: scale(1);
	transform: scale(1); }

	50% {
	-webkit-transform: scale(0.5);
	transform: scale(0.5);
	opacity: 0.7; }

	100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 1; }
}


.css3-spinner > .css3-spinner-clip-rotate {
	width: 33px;
	height: 33px;
	margin-top: -17px;
	margin-left: -17px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-clip-rotate > div {
	border-radius: 100%;
	border: 2px solid #DDD;
	border-bottom-color: transparent !important;
	height: 33px;
	width: 33px;
	background: transparent !important;
	-webkit-animation: rotate 1s 0s linear infinite;
	animation: rotate 1s 0s linear infinite;
}

@keyframes rotate {
	0% {
	-webkit-transform: rotate(0deg) scale(1);
	transform: rotate(0deg) scale(1); }

	50% {
	-webkit-transform: rotate(180deg) scale(0.6);
	transform: rotate(180deg) scale(0.6); }

	100% {
	-webkit-transform: rotate(360deg) scale(1);
	transform: rotate(360deg) scale(1); }
}


.css3-spinner > .css3-spinner-ball-rotate {
	width: 12px;
	height: 12px;
	margin-top: -6px;
	margin-left: -6px;
	background-color: transparent !important;
	-webkit-animation: ballrotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;
	animation: ballrotate 1s 0s cubic-bezier(.7, -.13, .22, .86) infinite;
}

.css3-spinner-ball-rotate > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	position: relative;
}

.css3-spinner-ball-rotate > div:nth-child(1), .css3-spinner-ball-rotate > div:nth-child(3) {
	width: 12px;
	height: 12px;
	border-radius: 100%;
	content: "";
	position: absolute;
	opacity: 0.7;
}

.css3-spinner-ball-rotate > div:nth-child(1) {
	top: 0px;
	left: -22px;
}

.css3-spinner-ball-rotate > div:nth-child(3) {
	top: 0px;
	left: 22px;
}


@-webkit-keyframes ballrotate {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }

  50% {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg); }

  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg); }
}

@keyframes ballrotate {
  0% {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg); }

  50% {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg); }

  100% {
	-webkit-transform: rotate(360deg);
	transform: rotate(360deg); }
}


.css3-spinner > .css3-spinner-zig-zag {
	background-color: transparent !important;
	-webkit-transform: translate(-15px, -15px);
	-ms-transform: translate(-15px, -15px);
	transform: translate(-15px, -15px);
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-zig-zag > div {
	background-color: #DDD;
	width: 12px;
	height: 12px;
	border-radius: 100%;
	position: absolute;
	margin-left: 15px;
	top: 4px;
	left: -7px;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner-zig-zag > div:first-child {
	-webkit-animation: ball-zig-deflect 1.5s 0s infinite linear;
	animation: ball-zig-deflect 1.5s 0s infinite linear;
}

.css3-spinner-zig-zag > div:last-child {
	-webkit-animation: ball-zag-deflect 1.5s 0s infinite linear;
	animation: ball-zag-deflect 1.5s 0s infinite linear;
}


@-webkit-keyframes ball-zig-deflect {
  17% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  34% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  84% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@keyframes ball-zig-deflect {
  17% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  34% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(15px, -30px);
	transform: translate(15px, -30px); }

  84% {
	-webkit-transform: translate(-15px, -30px);
	transform: translate(-15px, -30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@-webkit-keyframes ball-zag-deflect {
  17% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  34% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  84% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}

@keyframes ball-zag-deflect {
  17% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  34% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  50% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }

  67% {
	-webkit-transform: translate(-15px, 30px);
	transform: translate(-15px, 30px); }

  84% {
	-webkit-transform: translate(15px, 30px);
	transform: translate(15px, 30px); }

  100% {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0); }
}


.css3-spinner > .css3-spinner-ball-scale-multiple {
	width: 60px;
	height: 60px;
	margin-left: -30px;
	margin-top: -30px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-ball-scale-multiple > div {
	background-color: #DDD;
	border-radius: 100%;
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 60px;
	height: 60px;
	-webkit-animation: ball-scale-multiple 1.25s 0s linear infinite;
	animation: ball-scale-multiple 1.25s 0s linear infinite;
}

.css3-spinner-ball-scale-multiple > div:nth-child(2) {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}

.css3-spinner-ball-scale-multiple > div:nth-child(3) {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}

@-webkit-keyframes ball-scale-multiple {
  0% {
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0; }

  5% { opacity: 1; }

  100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0; }
}

@keyframes ball-scale-multiple {
  0% {
	-webkit-transform: scale(0);
	transform: scale(0);
	opacity: 0; }

  5% { opacity: 1; }

  100% {
	-webkit-transform: scale(1);
	transform: scale(1);
	opacity: 0; }
}


.css3-spinner > .css3-spinner-triangle-path {
	background-color: transparent !important;
	-webkit-transform: translate(-29.994px, -37.50938px);
	-ms-transform: translate(-29.994px, -37.50938px);
	transform: translate(-29.994px, -37.50938px);
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-triangle-path > div {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: #DDD;
}

.css3-spinner-triangle-path > div:nth-child(1) {
	-webkit-animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-child(2) {
	-webkit-animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-child(3) {
	-webkit-animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
	animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
}

.css3-spinner-triangle-path > div:nth-of-type(1) { top: 50px; }

.css3-spinner-triangle-path > div:nth-of-type(2) { left: 25px; }

.css3-spinner-triangle-path > div:nth-of-type(3) {
	top: 50px;
	left: 50px;
}

@-webkit-keyframes ball-triangle-path-1 {
  33% {
	-webkit-transform: translate(25px, -50px);
	transform: translate(25px, -50px); }

  66% {
	-webkit-transform: translate(50px, 0px);
	transform: translate(50px, 0px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-1 {
  33% {
	-webkit-transform: translate(25px, -50px);
	transform: translate(25px, -50px); }

  66% {
	-webkit-transform: translate(50px, 0px);
	transform: translate(50px, 0px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@-webkit-keyframes ball-triangle-path-2 {
  33% {
	-webkit-transform: translate(25px, 50px);
	transform: translate(25px, 50px); }

  66% {
	-webkit-transform: translate(-25px, 50px);
	transform: translate(-25px, 50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-2 {
  33% {
	-webkit-transform: translate(25px, 50px);
	transform: translate(25px, 50px); }

  66% {
	-webkit-transform: translate(-25px, 50px);
	transform: translate(-25px, 50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@-webkit-keyframes ball-triangle-path-3 {
  33% {
	-webkit-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px); }

  66% {
	-webkit-transform: translate(-25px, -50px);
	transform: translate(-25px, -50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}

@keyframes ball-triangle-path-3 {
  33% {
	-webkit-transform: translate(-50px, 0px);
	transform: translate(-50px, 0px); }

  66% {
	-webkit-transform: translate(-25px, -50px);
	transform: translate(-25px, -50px); }

  100% {
	-webkit-transform: translate(0px, 0px);
	transform: translate(0px, 0px); }
}


.css3-spinner > .css3-spinner-ball-pulse-sync {
	width: 70px;
	height: 34px;
	margin-left: -35px;
	margin-top: -17px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-ball-pulse-sync > div {
	display: inline-block;
	background-color: #DDD;
	width: 14px;
	height: 14px;
	margin: 10px 3px 0;
	border-radius: 100%;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.css3-spinner-ball-pulse-sync > div:nth-child(0) {
	-webkit-animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(1) {
	-webkit-animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(2) {
	-webkit-animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
}

.css3-spinner-ball-pulse-sync > div:nth-child(3) {
	-webkit-animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
	animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
}


@-webkit-keyframes ball-pulse-sync {
  33% {
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	opacity: 0.85;
	}

  66% {
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	opacity: 0.7;
	}

  100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	}
}

@keyframes ball-pulse-sync {
  33% {
	-webkit-transform: translateY(10px);
	transform: translateY(10px);
	opacity: 0.85;
	}

  66% {
	-webkit-transform: translateY(-10px);
	transform: translateY(-10px);
	opacity: 0.7;
	}

  100% {
	-webkit-transform: translateY(0);
	transform: translateY(0);
	opacity: 1;
	}
}


.css3-spinner > .css3-spinner-scale-ripple {
	width: 50px;
	height: 50px;
	margin-left: -25px;
	margin-top: -25px;
	background-color: transparent !important;
	-webkit-animation: none;
	animation: none;
}

.css3-spinner-scale-ripple > div {
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	position: absolute;
	top: 0;
	width: 50px;
	height: 50px;
	border-radius: 100%;
	border: 2px solid #DDD;
	-webkit-animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(.21, .53, .56, .8);
	animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(.21, .53, .56, .8);
}

.css3-spinner-scale-ripple > div:nth-child(0) {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}

.css3-spinner-scale-ripple > div:nth-child(1) {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}

.css3-spinner-scale-ripple > div:nth-child(2) {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}

.css3-spinner-scale-ripple > div:nth-child(3) {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}


@-webkit-keyframes ball-scale-ripple-multiple {
  0% {
	-webkit-transform: scale(0.1);
			transform: scale(0.1);
	opacity: 1; }

  70% {
	-webkit-transform: scale(1);
			transform: scale(1);
	opacity: 0.7; }

  100% {
	opacity: 0.0; }
}

@keyframes ball-scale-ripple-multiple {
  0% {
	-webkit-transform: scale(0.1);
			transform: scale(0.1);
	opacity: 1; }

  70% {
	-webkit-transform: scale(1);
			transform: scale(1);
	opacity: 0.7; }

  100% {
	opacity: 0.0; }
}



/* ----------------------------------------------------------------
	HTML5 Youtube Video Backgrounds
-----------------------------------------------------------------*/


.mb_YTVPlayer:focus { outline: 0; }

.mb_YTVPlayer {
	display:block;
	transform:translateZ(0);
	transform-style: preserve-3d;
	perspective: 1000;
	backface-visibility: hidden;
	transform:translate3d(0,0,0);
	animation-timing-function: linear;
}

.mb_YTVPlayer.fullscreen {
	display:block!important;
	position: fixed!important;
	width: 100%!important;
	height: 100%!important;
	top: 0!important;
	left: 0!important;
	margin: 0!important;
	border: none !important;
}

.mb_YTVPlayer.fullscreen .mbYTP_wrapper { opacity:1 !important; }


.mbYTP_wrapper iframe { max-width: 4000px !important; }

.inline_YTPlayer {
	margin-bottom: 20px;
	vertical-align:top;
	position:relative;
	left:0;
	overflow: hidden;
	border-radius: 4px;
	-moz-box-shadow: 0 0 5px rgba(0,0,0,.7);
	-webkit-box-shadow: 0 0 5px rgba(0,0,0,.7);
	box-shadow: 0 0 5px rgba(0,0,0,.7);
	background: rgba(0,0,0,.5);
}

.inline_YTPlayer img {
	border: none!important;
	-moz-transform: none!important;
	-webkit-transform: none!important;
	-o-transform: none!important;
	transform: none!important;
	margin:0!important;
	padding:0!important
}

/* ----------------------------------------------------------------
	Datepicker Component
-----------------------------------------------------------------*/

.datepicker td,
.datepicker th { padding: 5px; }

/* ----------------------------------------------------------------
	Star Ratings Component
-----------------------------------------------------------------*/

.rating-xl { font-size: 3rem !important; }

.rating-lg { font-size: 2.5rem !important; }

.rating-md { font-size: 2rem !important; }

.rating-sm { font-size: 1.75rem !important; }

.rating-xs { font-size: 1.25rem !important; }

.rating-container .star { margin: 0 2px !important; }

.rating-container .filled-stars {
	-webkit-text-stroke: 0px !important;
	text-shadow: none !important;
}


.irs-bar,
.irs-from,
.irs-to,
.irs-single,
.irs-handle > i:first-child,
.irs-handle.state_hover > i:first-child,
.irs-handle:hover > i:first-child { background-color: #1ABC9C !important; }

.irs-from::before,
.irs-to::before,
.irs-single::before { border-top-color: #1ABC9C !important; }




/* ----------------------------------------------------------------
	Custom Animate.css Styles
-----------------------------------------------------------------*/

@-webkit-keyframes fadeInDownSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInDownSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInDownSmall {
	-webkit-animation-name: fadeInDownSmall;
	animation-name: fadeInDownSmall;
}


@-webkit-keyframes fadeInLeftSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInLeftSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInLeftSmall {
	-webkit-animation-name: fadeInLeftSmall;
	animation-name: fadeInLeftSmall;
}


@-webkit-keyframes fadeInRightSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInRightSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInRightSmall {
	-webkit-animation-name: fadeInRightSmall;
	animation-name: fadeInRightSmall;
}


@-webkit-keyframes fadeInUpSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpSmall {
	from {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}

	to {
	opacity: 1;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
	}
}

.fadeInUpSmall {
	-webkit-animation-name: fadeInUpSmall;
	animation-name: fadeInUpSmall;
}


@-webkit-keyframes fadeOutDownSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}
}

@keyframes fadeOutDownSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, 50%, 0);
	transform: translate3d(0, 50%, 0);
	}
}

.fadeOutDownSmall {
	-webkit-animation-name: fadeOutDownSmall;
	animation-name: fadeOutDownSmall;
}


@-webkit-keyframes fadeOutLeftSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}
}

@keyframes fadeOutLeftSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(-50%, 0, 0);
	transform: translate3d(-50%, 0, 0);
	}
}

.fadeOutLeftSmall {
	-webkit-animation-name: fadeOutLeftSmall;
	animation-name: fadeOutLeftSmall;
}


@-webkit-keyframes fadeOutRightSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}
}

@keyframes fadeOutRightSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(50%, 0, 0);
	transform: translate3d(50%, 0, 0);
	}
}

.fadeOutRightSmall {
	-webkit-animation-name: fadeOutRightSmall;
	animation-name: fadeOutRightSmall;
}


@-webkit-keyframes fadeOutUpSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}
}

@keyframes fadeOutUpSmall {
	from {
	opacity: 1;
	}

	to {
	opacity: 0;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
	}
}

.fadeOutUpSmall {
	-webkit-animation-name: fadeOutUpSmall;
	animation-name: fadeOutUpSmall;
}



.dark #footer,
#footer.dark {
	color: rgba(255,255,255,0.75);
	background-color: #282828;
	border-top-color: rgba(255,255,255,0.15);
}

.dark .footer-widgets-wrap a {
	color: rgba(255,255,255,0.75);
}

.dark .footer-widgets-wrap a:hover {
	color: rgba(255,255,255,0.85);
}

.dark #footer .social-icon.si-borderless,
#footer.dark .social-icon.si-borderless {
	color: rgba(255,255,255,0.5) !important;
}

.dark #copyrights,
#copyrights.dark {
	color: rgba(255,255,255,0.4);
	background-color: rgba(0,0,0,0.2);
}

.dark .copyright-links a {
	color: rgba(255,255,255,0.4);
	border-bottom-color: rgba(255,255,255,0.65);
}

.dark .copyright-links a:hover {
	color: rgba(255,255,255,0.6);
	border-bottom-color: rgba(255,255,255,0.7);
}

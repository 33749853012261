.small-screen {
  padding-left: 10px;
}

.button-padding {
  margin: 8px 16px;
}

.card-section-wrapper {
  padding: 20px 0;
}

ul.DragDropContext {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; 
}

li.DragDropContext {
  display: inline-block;
  width: 33%;
}


.cabinet {
  height: 500px;
  background-color: #6c757d;
  border: 10px solid #343a40;
  position: relative;
  padding: 10px;
}

.shelf {
  height: 100px;
  background-color: #343a40;
  margin-bottom: 10px;
}

.toy {
  height: 100px;
  background-color: #ffc107;
  margin-bottom: 10px;
}

.toys-row {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}


.hero-container {
  position: relative;
  height: 500px;
  /* Set the desired height of your hero section */
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Scale the background image to cover the entire hero section */
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: black;
  /* Set the color of your text */
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20p
}

/* .hero-section {
  background-image: url('images/hero.jpg'), url('images/room_wide.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  animation: hero-animation 15s ease-in-out infinite;
}

@keyframes hero-animation {
  0% {
    background-image: url('images/hero.jpg');
  }
  33.3% {
    background-image: url('images/room_wide.jpg');
  }
  66.6% {
    background-image: url('images/room_left.jpg');
  }
  100% {
    background-image: url('images/funko.jpg');
  }
} */
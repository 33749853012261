.nav-span {
  display: block;
  text-align: center;
  font-size: 36px;
}

.nav-first {
  border-bottom: 2px solid #5e5e5e;
}

.nav-first:hover {
  background-color: #5e5e5e;
  color: white !important;
}

.nav-second {
  border-bottom: 2px solid red;
}

.nav-second:hover {
  background-color: red;
  color: white !important;
}

.nav-third {
  border-bottom: 2px solid blue;
}

.nav-third:hover {
  background-color: blue;
  color: white !important;
}

.nav-fourth {
  border-bottom: 2px solid orange;
}

.nav-fourth:hover {
  background-color: orange;
  color: white !important;
}

.nav-fifth {
  border-bottom: 2px solid green;
}

.nav-fifth:hover {
  background-color: green;
  color: white !important;
}

.logo-image {
  width: 96px;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background-color: blue;
  padding: 20px;
  border: 2px solid #3f51b5;
  border-radius: 10px;
  padding: 5px;

}
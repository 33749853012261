/* details section */

.details-collection {
    background-color:#30a7d7;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
}

/* end of details section */


/* TopSection.css */

.pagination-text {
    font-size: 16px;
  }
  

.top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f5f5f5; /* Light grey background */
    color: #333; /* Darker text color */
    padding: 16px;
    margin-bottom: 8px; /* Small margin at the bottom */
  }
  
  .pagination-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 28px; /* Slightly bigger font size */
    color: #333; /* Darker arrow color */
  }
  
  .pagination-icon {
    font-size: 28px; /* Slightly bigger icon size */
    margin-right: 8px; /* Spacing between arrow icon and text */
  }
  
  .header {
    text-align: center;
  }
  
  .header-title {
    font-size: 32px;
    margin: 0;
    padding: 0;
    font-family: 'Star Jedi', cursive;
    color: #ffdf00;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
  }
  
/* end of top section */
  


.collection-container{
    margin-left: 10%;
    margin-bottom: 10%;
    margin-right: 10%;
}

.exciting-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    padding-top: 20px;
    padding-bottom: 20px;
    
  }
  /* https://www.freepik.com/free-photo/abstract-luxury-gradient-blue-background-smooth-dark-blue-with-black-vignette-studio-banner_16441862.htm#query=background%20image&position=38&from_view=search&track=ais */
  .diagonal-lines {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    /* background-image: url('../../public/images/collection1.jpg'); */
    background-color: lightgray;
    z-index: -1; /* Push the diagonal lines behind the image */
  }
  
  .image {
    display: block;
    width: 80%;
    border: 4px solid #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }

  .image-small {
    width: 64px;
    border: 4px solid #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  

.header-stats {
    font-family: "Flexo-Medium",arial,sans-serif;
    clear: both;
    color: #313131;
    float: left;
    font-size: 100%;
    margin: 0.75em 1.5em 1.5em;
    text-transform: none;
}

.bar-container {

    background-color: #a4a4a4;
    border-radius: 10px;
    display: block;
    float: left;
    width: 100%;    
    margin-bottom: 16px;
}

.bar-container>ul{


    clear: both;
    display: block;
    margin-bottom: 1.5em;
    position: relative;
    width: 100%;
}

.bar-container>ul>li {
    float: left;
    margin-right: -100%;
    width: 12.95%;
    display: inline-block;
    margin: 0 1.5625% 1.25em 0;

    list-style-type: none;
}

/* .bar-graph{
    list-style: none;
    background: blue;
    clear: both;
    display: block;
    margin-bottom: 1.5em;
    position: relative;
    width: 100%;
} */

.gauge {
    background: #fff;
    position: relative;
    overflow: hidden;

    list-style-type: none;

    padding-left: 0px;
}

.gauge li.meter {
    background: #30a7d7;
    border: none;
    height: 120%;
    position: absolute;
    top: 100%;
    z-index: 1;
}

.gauge li {
    background: transparent;
    border-bottom: 0.25em solid #a4a4a4;
    height: 0.5em;
    width: 100%;
    position: relative;
    z-index: 2;
}

.bar-container .gauge {
    background: #fff;
    position: relative;
    overflow: hidden
}

.bar-container .gauge li {
    background: transparent;
    border-bottom: 0.25em solid #a4a4a4;
    height: 0.5em;
    width: 100%;
    position: relative;
    z-index: 2
}

.bar-container .gauge li.meter {
    background: #30a7d7;
    border: none;
    height: 120%;
    position: absolute;
    top: 100%;
    z-index: 1
}

.pokedex-pokemon-details-right .version-descriptions {
    display: none
}

.pokedex-pokemon-details-right .version-descriptions.active {
    display: block
}

.pokedex-pokemon-details-right .version-descriptions p {
    font-family: "Flexo-Medium",arial,sans-serif;
    color: #212121;
    font-size: 112.5%;
    line-height: 150%;
    display: none
}

body.ru .pokedex-pokemon-details-right .version-descriptions p {
    font-family: "Roboto",arial,sans-serif;
    font-weight: 500
}

.pokedex-pokemon-details-right .version-descriptions p.active {
    display: block
}

.pokedex-pokemon-details-right>h3 {
    font-family: "Flexo-Medium",arial,sans-serif;
    color: #212121;
    float: left;
    font-size: 112.5%;
    line-height: 150%;
    margin: 1.125em 0 2em;
    text-transform: none
}

body.ru .pokedex-pokemon-details-right>h3 {
    font-family: "Roboto",arial,sans-serif;
    font-weight: 500
}

.pokedex-pokemon-details-right .version-labels {
    *zoom:1}

.pokedex-pokemon-details-right .version-labels:before,.pokedex-pokemon-details-right .version-labels:after {
    content: "";
    display: table
}

.pokedex-pokemon-details-right .version-labels:after {
    clear: both
}

.pokedex-pokemon-details-right .version-labels .version-label {
    font-family: "Flexo-Bold",arial,sans-serif;
    border-color: #fff;
    border-style: solid;
    border-width: 4px;
    border-radius: 32px;
    cursor: pointer;
    float: left;
    font-size: 112.5%;
    height: 32px;
    line-height: 32px;
    margin: 0.75em 0 0.75em 0.5em;
    text-transform: none;
    text-align: center;
    vertical-align: middle;
    width: 32px
}

body.ru .pokedex-pokemon-details-right .version-labels .version-label {
    font-family: "Roboto",arial,sans-serif;
    font-weight: bold
}
.carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carousel-wrapper {
  position: relative;
}

.carousel-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-buttons button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  opacity: 0.8;
  cursor: pointer;
}

.carousel-buttons button:hover {
  opacity: 1;
}

.carousel-content-wrapper{
  background-color: lightgrey;
}

.collection {
  background-color: lightgrey;
  padding: 20px;
}

.collection-item .card.blue {
  background-color: #0074D9;
}

.collection-item .card.orange {
  background-color: #FFA500;
}

.collection-item .card.red {
  background-color: #FF4136;
}

.collection-item .card.yellow {
  background-color: #FFDC00;
}

.collection-item .card {
  border: 20px solid white;
  border-radius: 50px;
  color: #C0C0C0;
  font-family: 'Roboto', sans-serif;
  box-shadow: 0px 0px 10px 2px rgba(255, 255, 255, 0.7), 0px 0px 20px 4px rgba(255, 255, 255, 0.5), 0px 0px 30px 6px rgba(255, 255, 255, 0.3), 0px 0px 40px 8px rgba(255, 255, 255, 0.1);
}

.collection-item .card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
}

.collection-item .card-text {
  font-size: 18px;
}

.collection-item .card-img-top {
  background-color: #C0C0C0;
  border: 10px solid #C0C0C0;
  height: 300px;
  padding: 20px;
  position: relative;
  width: 200px;
  background-image: linear-gradient(to bottom, #F0F0F0, #C0C0C0);
  box-shadow: inset 0px 0px 10px rgba(255, 255, 255, 0.6), inset 0px 0px 15px rgba(255, 255, 255, 0.4), inset 0px 0px 20px rgba(255, 255, 255, 0.2), 0px 0px 10px rgba(0, 0, 0, 0.3);
}


.collection-item .card-img-top img {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: auto;
}

.collection-item .container {
  padding-top: 50px;
  background-color: lightgrey;
}

.collection-item .row {
  display: flex;
  justify-content: center;
}
